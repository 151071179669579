import { apolloClient, GET_LIST_JOBS_SEO_QUERY } from '~/common/apollo'
import { action, computed, observable, store } from '~/common/mobx.decorator'

@store()
class CareListJobStore {
	@observable seoPathJobs = []
	@observable seoPathJob
	@observable totalCount

	@computed
	get seoPathJobsItems() {
		return this.seoPathJobs
	}

	@computed
	get hasNextPage() {
		return this.seoPathJobs?.length < this.totalCount
	}

	@action
	fetchListJobs = async (seoPath) => {
		const response = await apolloClient.query({
			query: GET_LIST_JOBS_SEO_QUERY,
			variables: { seoPath: seoPath, offset: 0, limit: 20 },
		})
		this.seoPathJobs = response?.data?.jobsBySeoPath?.jobs
		this.seoPathJob = response?.data?.jobsBySeoPath?.job
		this.totalCount = response?.data?.jobsBySeoPath?.totalCount
	}

	@action
	onScrollToEnd = async (seoPath) => {
		const response = await apolloClient.query({
			query: GET_LIST_JOBS_SEO_QUERY,
			variables: {
				seoPath: seoPath,
				limit: 20,
				offset: this.seoPathJobs?.length || 0,
			},
		})

		this.seoPathJobs = [...this.seoPathJobs, ...response?.data?.jobsBySeoPath?.jobs]
	}
	@action
	setSeoPathJob = async (job) => {
		this.seoPathJob = job
	}
}

export const listJobStore = new CareListJobStore()
