import { Box, Chip, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useField, useFormikContext } from 'formik'
import { filter, find, get, includes, isNil, map } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { MdExpandMore } from 'react-icons/md'
import { useDebounce, usePrevious } from 'react-use'
import { FIELD_MODE } from '~/common/constants'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { LabelField } from '@opus/web.core.form.label-field'
import { makeStyles } from '@material-ui/core/styles'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { appStore } from '~/stores'
import { css } from 'styled-components'

export const labelStyle = css`
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1px;
	color: ${({ theme }) => theme.palette.primary.main} !important;
	text-transform: capitalize;
	.MuiFormLabel-root.Mui-disabled {
		color: ${({ theme }) => theme.overrides.MuiButton.containedPrimary.color} !important;
	}
	.MuiInputBase-root.Mui-disabled {
		color: ${({ theme }) => theme.palette.content.veryDark};
	}
`

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(1),
		width: 'max-content',
	},
}))

export const AutoCompleteField = ({
	name,
	validate,
	label,
	placeholder,
	disabled,
	options,
	freeSolo,
	allowAddValue,
	mode,
	parentName,
	multiple,
	disableClearable,
	fullWidth = true,
	handleUpdateChange,
	hasAllowChangeField = false,
	...props
}) => {
	const { t } = useTranslation()
	const { values } = useFormikContext()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const classes = useStyles()
	const [inputValue, updateInputValue] = useState(field.value)
	const [focused, focusProps] = useFieldFocused(field)

	const error = useFieldError(meta)

	const handleChange = useCallback(
		(_, option) => {
			updateInputValue(multiple ? option?.map((opt) => opt.value) : option?.value)
		},
		[updateInputValue, multiple]
	)

	useDebounce(
		() => {
			if (field.value !== inputValue && !focused) {
				updateInputValue(field.value || (multiple ? [] : ''))
			}
		},
		0,
		[field.value]
	)

	useDebounce(
		() => {
			field.onChange({ target: { name, value: inputValue || '' } })
			if (hasAllowChangeField && inputValue !== '' && focused && !isNil(inputValue)) {
				handleUpdateChange()
			}
		},
		0,
		[inputValue]
	)

	const parentValue = useMemo(() => get(values, parentName), [values, parentName])

	const finalOptions = useMemo(() => {
		const items = parentName ? options?.filter((option) => option.parentValue === parentValue) : options

		return map(items, (option) => (option.label ? option : { ...option, label: option.value }))
	}, [options, parentName, parentValue])

	const selectedOption = useMemo(() => {
		if (multiple) {
			return filter(finalOptions, (option) => includes(field.value, option.value)) || []
		}

		return find(finalOptions, (option) => option.value === field.value) || (freeSolo && inputValue ? { value: inputValue, label: inputValue } : null)
	}, [finalOptions, field.value, multiple, freeSolo, inputValue])

	const prevParentValue = usePrevious(parentValue)

	useDebounce(
		() => {
			if (mode === FIELD_MODE.edit && parentName && prevParentValue !== parentValue) {
				field.onChange({ target: { name, value: null } })
			}
		},
		0,
		[parentValue, prevParentValue]
	)

	const viewValue = useMemo(() => (multiple ? selectedOption?.map((opt) => opt.label)?.join(', ') : selectedOption?.label), [multiple, selectedOption])

	if (mode === FIELD_MODE.view) {
		if (multiple) {
			return (
				<Box>
					<Box mb={2} css={labelStyle}>
						<label> {t(label)} </label>
					</Box>
					{viewValue &&
						viewValue?.split(',').map((item) => (
							<span>
								{' '}
								<Chip label={`${item}`} />{' '}
							</span>
						))}
				</Box>
			)
		} else {
			return <LabelField label={t(label)} displayValueFormat={() => viewValue || field.value} />
		}
	}

	return (
		<Autocomplete
			classes={{ paper: classes.paper }}
			{...props}
			closeIcon={
				<CancelRoundedIcon
					onClick={() => {
						if (hasAllowChangeField) {
							handleUpdateChange()
							field.onChange({ target: { name, value: null } })
						}
					}}
					css={[
						{
							fill: appStore.getTheme.palette.primary.main,
							fontSize: '20px',
						},
					]}
				/>
			}
			id={name}
			freeSolo={freeSolo}
			options={finalOptions}
			getOptionLabel={(option) => (option.isNew ? `Add "${option.label}"` : option.label || '')}
			getOptionSelected={(option, value) => option.value === value?.value}
			selectOnFocus
			filterOptions={(options, params) => {
				if (!params.inputValue) {
					return options
				}

				const filtered = filter(options, (option) => option?.label?.toLowerCase()?.includes(params?.inputValue?.toLowerCase()))

				// Suggest the creation of a new value
				if (params.inputValue !== '' && allowAddValue) {
					filtered.push({
						value: params.inputValue,
						label: params.inputValue,
						isNew: true,
					})
				}

				return filtered
			}}
			fullWidth={fullWidth}
			multiple={multiple}
			{...field}
			{...focusProps}
			disabled={disabled}
			disableClearable={disableClearable}
			value={selectedOption}
			onChange={handleChange}
			popupIcon={<MdExpandMore />}
			renderInput={(params) => (
				<TextField {...params} label={t(label)} disabled={disabled} placeholder={placeholder && t(placeholder)} error={!!error} helperText={error} />
			)}
		/>
	)
}

AutoCompleteField.defaultProps = {
	freeSolo: false,
	allowAddValue: false,
	options: [],
	mode: FIELD_MODE.edit,
	multiple: false,
}
