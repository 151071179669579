import { isArray, isEmpty, isNil, isString, omit } from 'lodash'
import { careFindJobStore } from '~/features/care-find-job/care-find-job.store'
import moment from 'moment'
import { MAX_WAGE_WEEKLY, MAX_WAGE_YEARLY } from '../constants/search'

export const toSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const mappingJobSearchData = (lastSaveSearch) => {
	const lastSaveSearchFilter = {}
	if (lastSaveSearch) {
		const {
			external_id,
			keyword,
			max_wage,
			min_wage,
			near_by,
			min_matching_percentage,
			radius,
			shifts,
			skill_name,
			profession,
			posted_in_days,
			job_types,
			location_states,
			location_cities,
			start_date,
			frequency,
		} = lastSaveSearch

		// lastSaveSearchFilter.exclusiveJob = exclusive_job

		if (external_id) {
			lastSaveSearchFilter.externalId = external_id
		}
		if (start_date) {
			if (isArray(start_date)) {
				lastSaveSearchFilter.startDate = {
					from: mappingDate(start_date[0]),
					to: mappingDate(start_date[1]),
				}
			} else {
				lastSaveSearchFilter.startDate = {
					from: mappingDate(start_date.from),
					to: mappingDate(start_date.to),
				}
			}
		}
		if (keyword) {
			lastSaveSearchFilter.keyword = keyword
		}
		if (max_wage) {
			lastSaveSearchFilter.maxWage = max_wage
		}
		if (near_by) {
			lastSaveSearchFilter.nearBy = near_by
		}
		if (min_wage) {
			lastSaveSearchFilter.minWage = min_wage
		}

		if (posted_in_days) {
			lastSaveSearchFilter.postedInDays = posted_in_days
		}
		if (profession) {
			lastSaveSearchFilter.profession = profession
		}

		if (location_cities) {
			lastSaveSearchFilter.locationCities = location_cities
		}

		if (location_states) {
			lastSaveSearchFilter.locationStates = location_states
		}
		if (job_types) {
			lastSaveSearchFilter.jobTypes = job_types
		}

		if (min_matching_percentage) {
			lastSaveSearchFilter.minMatchingPercentage = min_matching_percentage
		}

		if (radius) {
			lastSaveSearchFilter.radius = radius
			lastSaveSearchFilter.initRadius = radius
		}

		if (shifts) {
			lastSaveSearchFilter.shifts = shifts
		}

		if (skill_name) {
			lastSaveSearchFilter.skillName = skill_name
		}

		if (frequency) {
			lastSaveSearchFilter.frequency = frequency
		}
	}

	return lastSaveSearchFilter
}

export const mappingFilterJobSearch = (searchData, isShowLocation = false) => {
	const searchDataFilter = {}
	if (searchData) {
		const {
			minMatchingPercentage,
			nearBy,
			shifts,
			profession,
			externalId,
			isActive,
			keyword,
			wage,
			location,
			jobTypes,
			locationStates,
			locationCities,
			skillName,
			specialty,
			startDate,
			filterDate,
			frequency,
		} = searchData

		if (keyword) {
			searchDataFilter.keyword = keyword
		}
		if (externalId) {
			searchDataFilter.externalId = externalId
		} else {
			if (filterDate) {
				searchDataFilter.filterDate = filterDate
			}

			if (profession) {
				searchDataFilter.profession = profession
			}

			if (!isNil(minMatchingPercentage)) {
				searchDataFilter.minMatchingPercentage = minMatchingPercentage
			}

			if (shifts) {
				searchDataFilter.shifts = shifts
			}

			if (skillName) {
				searchDataFilter.skillName = skillName
			}

			if (specialty) {
				searchDataFilter.skillName = specialty.map((item) => item.value)
			}

			if (externalId) {
				searchDataFilter.externalId = externalId
			}
			if (jobTypes) {
				searchDataFilter.jobTypes = jobTypes
			}
			if (!isEmpty(startDate)) {
				if (isArray(startDate)) {
					searchDataFilter.startDate = {
						from: mappingDate(startDate[0]),
						to: mappingDate(startDate[1]),
					}
				} else {
					searchDataFilter.startDate = {
						from: mappingDate(startDate.from),
						to: mappingDate(startDate.to),
					}
				}
			} else {
				searchDataFilter.startDate = null
			}
			if (locationStates) {
				searchDataFilter.locationStates = locationStates
			}
			if (nearBy) {
				searchDataFilter.nearBy = {
					lat: null,
					long: null,
					radius: Number(nearBy?.radius) || 50,
				}
			}
			if (locationCities) {
				searchDataFilter.locationCities = locationCities
			}
			if (location) {
				const cities = location.filter((location) => location.type === 'locationCity').map((item) => item.value)
				const states = location.filter((location) => location.type === 'locationState').map((item) => item.value)

				searchDataFilter.locationCities = cities
				searchDataFilter.locationStates = states

				if (cities.length === 1 || states.length > 0) {
					searchDataFilter.nearBy = {
						lat: null,
						long: null,
						radius: Number(nearBy?.radius) || 50,
					}
				}
			}

			if (wage) {
				let maxWage = wage[1]
				if (frequency === 'yearly' && maxWage >= MAX_WAGE_YEARLY) {
					maxWage = null
				} else if (maxWage >= MAX_WAGE_WEEKLY) {
					maxWage = null
				}
				searchDataFilter.minWage = wage[0]
				if (maxWage) {
					searchDataFilter.maxWage = maxWage
				}
			}
			if (isActive) {
				searchDataFilter.isActive = isActive
			}

			if (frequency) {
				searchDataFilter.frequency = frequency
			}
		}
	}
	return searchDataFilter
}

export const convertStateToArray = (data) => {
	return {
		...data,
		locationState: isString(data?.locationStates) ? data?.locationStates?.split(',') : data?.locationStates,
	}
}

export const convertStateToString = (data) => {
	return {
		...data,
		locationStates: data?.locationStates,
	}
}

export const mappingSkillName = (data) => {
	const skillNameLength = data?.skillName?.length
	const profession = data?.profession

	return (!!profession && skillNameLength === 0) || (!!profession && !skillNameLength)
		? {
				...data,
				skillName: careFindJobStore.disciplineOptions(profession).map((item) => item?.value),
		  }
		: data
}

export const convertCommaSeparatedValuesToArray = (obj) => {
	for (let key in obj) {
		if (typeof obj[key] === 'string' && obj[key].includes(',')) {
			obj[key] = obj[key].split(',')
		}
	}
	return obj
}
export const mappingDate = (data) => {
	if (typeof data === 'object') {
		const originalDate = moment(data, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zz)')
		return originalDate.format('YYYY-MM-DD')
	} else {
		return data
	}
}

export const convertToCorrectTypes = async (object) => {
	const allowedKeys = [
		'keyword',
		'skillName',
		'startDate',
		'specialty',
		'shifts',
		'locationCity',
		'locationState',
		'profession',
		'jobTypes',
		'minWage',
		'maxWage',
		'minMatchingPercentage',
		'nearBy',
		'frequency',
	]

	const values = Object.keys(object)
		.filter((key) => allowedKeys.includes(key))
		.reduce((filteredObj, key) => {
			filteredObj[key] = object[key]
			return filteredObj
		}, {})
	if (values?.shifts?.length > 0) {
		values.shifts = values?.shifts?.split(',')
	}
	if (values?.jobTypes?.length > 0) {
		values.jobTypes = values?.jobTypes?.split(',')
	}
	if (!!values.startDate) {
		const [from, to] = values.startDate.split(',')
		values.startDate = {
			from,
			to,
		}
	}

	if (values.hasOwnProperty('locationState')) {
		values.locationStates = values?.locationState?.split(',')
	}

	if (values.hasOwnProperty('locationCity')) {
		values.locationCities = formatCityState(values?.locationCity)
		if (values.hasOwnProperty('nearBy') && !isEmpty(values?.locationCity)) {
			values.nearBy = {
				lat: null,
				long: null,
				radius: values?.nearBy || 50,
			}
		}
	}
	if (values.hasOwnProperty('minMatchingPercentage')) {
		values.minMatchingPercentage = Number(values?.minMatchingPercentage)
	}

	if (values.hasOwnProperty('skillName')) {
		values.skillName = values?.specialty?.split(',')
		delete values.specialty
	}
	if (values.hasOwnProperty('specialty')) {
		values.skillName = values?.specialty?.split(',')
		delete values.specialty
	}
	return values
}

export const mappingObjectToArrayField = (obj) => {
	return Object.entries(obj).map(([key, value]) => {
		return {
			key,
			value,
		}
	})
}

export function convertToUSPhoneFormat(phoneNumber) {
	if (phoneNumber) {
		// Remove the leading '+1'
		let formattedNumber
		phoneNumber = phoneNumber.substring(2)
		// Remove any non-numeric characters from the input phone number
		phoneNumber = phoneNumber.replace(/\D+/g, '')
		// Check if the phone number is 10 digits or 7 digits
		if (phoneNumber.length === 10) {
			// Format the phone number as "(xxx)-xxx-xxxx"
			formattedNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
		} else if (phoneNumber.length === 9) {
			// Format the phone number as "(xxx)-xxx-xxx"
			formattedNumber = phoneNumber.replace(/(\d{3})(\d{4})/, '$1-$2')
		} else {
			// Return an error message for invalid phone numbers
			return 'Error: Invalid phone number'
		}
		return formattedNumber
	}
}

export function convertKeysToSnakeCase(obj) {
	if (typeof obj !== 'object' || obj === null) {
		return obj // Return as is if obj is not an object or is null
	}

	if (Array.isArray(obj)) {
		// If obj is an array, recursively convert keys for each item in the array
		return obj.map((item) => convertKeysToSnakeCase(item))
	}

	const snakeCaseObj = {}
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			// Convert key to snake case
			const snakeCaseKey = key.replace(/([A-Z])/g, '_$1').toLowerCase()
			// Recursively convert keys for nested objects
			snakeCaseObj[snakeCaseKey] = convertKeysToSnakeCase(obj[key])
		}
	}
	return snakeCaseObj
}

export function listBannerDismiss(banners) {
	let result = {}

	const isDisMiss = !!banners?.find((x) => !x.isDismissed)

	if (!!banners) {
		banners.forEach((banner) => {
			result.activeWelcomeBanner = {
				isDismissed: isDisMiss,
				id: banner?.id,
			}
		})
	}
	return result
}

export const mappingStatusSpecialty = (data, isNoSkillCheckList) => {
	if (isNoSkillCheckList === false) {
		if (data === 'pending' || data === 'in_progress' || data === 'updated_questions' || data === 'part_updated_questions') {
			return 'pending'
		} else if (data === 'expired') {
			return 'expired'
		} else if (data === 'completed') {
			return 'completed'
		}
	}
}

export const mappingStatusSpecialtyEdit = (data, isNoSkillCheckList) => {
	if (isNoSkillCheckList === false) {
		if (data === 'pending' || data === 'in_progress' || data === 'updated_questions' || data === 'part_updated_questions') {
			return 'Incomplete skill checklist'
		} else if (data === 'expired') {
			return 'Skill checklist expired - Need to be updated'
		} else if (data === 'completed') {
			return 'Completed'
		}
	}
}

export const mappingStatusSpecialtyInfo = (data) => {
	switch (data) {
		case 'pending':
			return { isShow: false, status: null }
		case 'in_progress':
			return { isShow: false, status: null }
		case 'updated_questions':
			return { isShow: true, status: 'The Skills Checklist has been updated with new questions.' }
		case 'part_updated_questions':
			return { isShow: true, status: 'The Skills Checklist has been updated with new questions.' }
		case 'expired':
			return { isShow: true, status: 'Skill checklist expired - Need to be updated' }
		case 'completed':
			return { isShow: false, status: null }
		default:
			return { isShow: false, status: null }
	}
}

export const handleMappingSeoConfig = (data) => {
	const filterCondidationSeo = {}
	const { nearBy, shifts, jobTypes, startDate, skillName, locationStates, locationCities, frequency, ...rest } = data

	const from = startDate?.from
	const to = startDate?.to
	if (!!from && !!to) {
		filterCondidationSeo.startDate = [from, to]
	}

	if (!isEmpty(skillName)) {
		filterCondidationSeo.specialty = skillName
	}

	if (!isEmpty(locationStates)) {
		filterCondidationSeo.locationState = locationStates
	}
	if (!isEmpty(jobTypes)) {
		filterCondidationSeo.jobTypes = jobTypes
	}
	if (!isEmpty(shifts)) {
		filterCondidationSeo.shifts = shifts
	}
	if (!isEmpty(frequency)) {
		filterCondidationSeo.frequency = frequency
	}

	if (!isEmpty(locationCities)) {
		filterCondidationSeo.locationCity = locationCities
		if (!isNil(nearBy)) {
			filterCondidationSeo.nearBy = nearBy.radius
		}
	}

	const finalItem = { ...rest, ...filterCondidationSeo }
	return new URLSearchParams(finalItem).toString()
}

export function removeFalsyProps(obj) {
	// Create a new object to store the truthy properties
	const newObj = {}

	// Loop through each property in the original object
	for (const key in obj) {
		// Check if the property value is truthy or null/undefined
		if (obj[key] || obj[key] === '' || obj[key] === [] || obj[key] === 0) {
			// Add the property to the new object
			newObj[key] = obj[key]
		} else if (obj[key] === null || obj[key] === undefined) {
			// Replace null/undefined values with empty string
			newObj[key] = ''
		}
	}

	// Return the new object with only truthy properties
	return newObj
}

export function formatCityState(input) {
	const parts = input.split(',')
	const formattedResults = []

	for (let i = 0; i < parts.length; i += 2) {
		const city = parts[i].trim()
		const state = parts[i + 1].trim()
		const formatted = `${city}, ${state}`
		formattedResults.push(formatted)
	}

	return formattedResults
}
export const mappingFilterDate = (value) => {
	if (value.filterDate === 'last24Hours') {
		return omit({ ...value, postedInDays: 1 }, ['filterDate'])
	}
	if (value.filterDate === 'last3Days') {
		return omit({ ...value, postedInDays: 3 }, ['filterDate'])
	}
	if (value.filterDate === 'last7Days') {
		return omit({ ...value, postedInDays: 7 }, ['filterDate'])
	}
	if (value.filterDate === 'last14Days') {
		return omit({ ...value, postedInDays: 14 }, ['filterDate'])
	}
	if (value.filterDate === 'lastVisit') {
		return omit({ ...value, showFromLastVisit: true }, ['filterDate'])
	}
	return value
}
